import React from "react";
import styled from "styled-components";

const MediumIcon = (props) => {
  return (
    <Div mr={props.mr}>
      <svg
        width={props.width}
        height="auto"
        viewBox="0 0 31 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Path d="M5.53484 9.3889C5.56455 9.08923 5.45088 8.79086 5.22484 8.58936L2.93859 5.83423V5.4209H10.0402L15.5298 17.4592L20.3554 5.4209H27.125V5.83294L25.1682 7.70715C25.0003 7.83632 24.9189 8.04815 24.9512 8.25611V22.033C24.9189 22.2423 25.0003 22.4528 25.1682 22.582L27.0786 24.4562V24.8682H17.4737V24.4562L19.4487 22.5355C19.645 22.3417 19.645 22.2836 19.645 21.9878V10.8511L14.1438 24.823H13.4011L6.99701 10.8511V20.2144C6.94405 20.6083 7.07451 21.0049 7.35222 21.2903L9.92522 24.4123V24.8243H2.62988V24.4123L5.20288 21.2903C5.47672 21.0049 5.60072 20.6057 5.53484 20.2144V9.3889Z" />
      </svg>
    </Div>
  );
};

export default MediumIcon;

const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${(props) => props.mr || "0"};
`;

const Path = styled.path`
  fill: ${(props) => props.theme.fontColorAlt};
`;
