import React from "react";
import styled from "styled-components";

const BackIcon = (props) => {
  return (
    <Div>
      <svg
        width="40"
        height="auto"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.3837 7.86621L20.5175 15L13.3837 22.1337L11.6162 20.3662L16.9825 15L11.6162 9.63371L13.3837 7.86621Z"
          fill="#ffffff"
        />
      </svg>
    </Div>
  );
};

export default BackIcon;

const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 5px;
`;

// const Path = styled.path`
//   fill: ${(props) => props.theme.fontColorAlt};
// `;
