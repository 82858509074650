import React from "react";
import styled from "styled-components";

const VoteIcon = (props) => {
  return (
    <Div mr={props.mr}>
      <svg
        width={props.width}
        height="auto"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Path
          d="M7.5 26.25H3.75C3.41848 26.25 3.10054 26.1183 2.86612 25.8839C2.6317 25.6495 2.5 25.3315 2.5 25V15C2.5 14.6685 2.6317 14.3505 2.86612 14.1161C3.10054 13.8817 3.41848 13.75 3.75 13.75H7.5C7.83152 13.75 8.14946 13.8817 8.38388 14.1161C8.6183 14.3505 8.75 14.6685 8.75 15V25C8.75 25.3315 8.6183 25.6495 8.38388 25.8839C8.14946 26.1183 7.83152 26.25 7.5 26.25ZM16.25 26.25H12.5C12.1685 26.25 11.8505 26.1183 11.6161 25.8839C11.3817 25.6495 11.25 25.3315 11.25 25V3.75C11.25 3.41848 11.3817 3.10054 11.6161 2.86612C11.8505 2.6317 12.1685 2.5 12.5 2.5H16.25C16.5815 2.5 16.8995 2.6317 17.1339 2.86612C17.3683 3.10054 17.5 3.41848 17.5 3.75V25C17.5 25.3315 17.3683 25.6495 17.1339 25.8839C16.8995 26.1183 16.5815 26.25 16.25 26.25ZM25 26.25H21.25C20.9185 26.25 20.6005 26.1183 20.3661 25.8839C20.1317 25.6495 20 25.3315 20 25V11.25C20 10.9185 20.1317 10.6005 20.3661 10.3661C20.6005 10.1317 20.9185 10 21.25 10H25C25.3315 10 25.6495 10.1317 25.8839 10.3661C26.1183 10.6005 26.25 10.9185 26.25 11.25V25C26.25 25.3315 26.1183 25.6495 25.8839 25.8839C25.6495 26.1183 25.3315 26.25 25 26.25Z"
          fill="#646775"
        />
      </svg>
    </Div>
  );
};

export default VoteIcon;

const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${(props) => props.mr || "0"};
`;

const Path = styled.path`
  fill: ${(props) => props.theme.fontColorAlt};
`;
