import React from "react";
import styled from "styled-components";

function CallToAction() {
  return (
    <Header>
      <Container>
        <StyledText>Get Started Now.</StyledText>
        <a href="https://app.saffron.finance/" target="_blank" rel="noreferrer">
          <Button>Launch Application</Button>
        </a>
      </Container>
    </Header>
  );
}

export default CallToAction;

// styles

const Header = styled.div`
  margin: 0 auto;
  max-width: 1300px;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: ;
`;

const StyledText = styled.h1`
  color: ${(props) => props.theme.fontColor};
  font-weight: 500;
  font-size: 35px;
  margin: 0;
  @media only screen and (max-width: 371px) {
    font-size: 30px;
  }
`;

const Button = styled.button`
  font-family: "Lexend", sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  border-radius: 5px;
  border: none;
  width: 190px;
  height: 40px;
  background-color: #c44536;
  transition: 0.4s;
  cursor: pointer;
  margin-top: 45px;
  &:hover {
    opacity: 0.8;
  }
`;
