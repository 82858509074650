import React from "react";
import styled from "styled-components";

const BackIcon = (props) => {
  return (
    <Div>
      <svg
        width={props.width}
        height="auto"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Path
          d="M16.6163 7.86621L9.48251 15L16.6163 22.1337L18.3838 20.3662L13.0175 15L18.3838 9.63371L16.6163 7.86621Z"
          fill="#646775"
        />
      </svg>
    </Div>
  );
};

export default BackIcon;

const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
`;

const Path = styled.path`
  fill: ${(props) => props.theme.fontColorAlt};
`;
