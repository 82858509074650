import styled, { css } from "styled-components";
// import { lightTheme, darkTheme, Globalstyles } from "../../themes";

// assets
import HeaderImage from "../../assets/saffronfeatures.svg";

function Learn(props) {
  return (
    <Header>
      <Container>
        <HeaderDiv>
          <Img src={HeaderImage} />
        </HeaderDiv>
        <Row>
          <PreTitle>About</PreTitle>
          <StyledText>What is Saffron?</StyledText>
          <SubText>
            Saffron Finance is a protocol that allows users (i.e. liquidity
            providers) to have access to customizable and dynamic risk/reward
            exposures on the pools that they choose to interact with. The
            protocol's main use case is to act as an intermediary between
            liquidity providers and lending protocols, where liquidity providers
            can provide liquidity to lending protocols through various SFI
            tranches. The protocol's native token SFI is used to gain access to
            specified tranches on the protocol via staking as well as to govern
            the protocol.
          </SubText>
          <BtnRow>
            <A
              href="https://academy.saffron.finance/blog/introduction-to-saffron-finance"
              target="_blank"
            >
              <Button>Learn More</Button>
            </A>
          </BtnRow>
        </Row>
      </Container>
    </Header>
  );
}

export default Learn;

const Header = styled.div`
  margin: 0 auto;
  max-width: 1300px;
  width: 100%;
  padding-top: 50px;
  @media only screen and (max-width: 1022px) {
    padding-top: 100px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 1070px) {
    flex-direction: column-reverse;
    justify-content: center;
    text-align: center;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1328px) {
    padding-right: 15px;
  }
  @media only screen and (max-width: 1070px) {
    padding-right: 0px;
  }
`;

const HeaderDiv = styled.div`
  @media only screen and (max-width: 1328px) {
    padding-left: 15px;
  }
  @media only screen and (max-width: 1070px) {
    padding-left: 0px;
    padding-top: 80px;
  }
`;

const Img = styled.img``;

const PreTitle = styled.span`
  color: ${(props) => props.theme.fontColorAlt};
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  margin-bottom: 10px;
`;

const StyledText = styled.h1`
  color: ${(props) => props.theme.fontColor};
  font-weight: 500;
  font-size: 28px;
  margin: 0;
  ${(props) =>
    props.alt &&
    css`
      font-size: 21px;
      font-weight: 400;
      margin-top: 25px;
    `}
`;

const SubText = styled.p`
  color: ${(props) => props.theme.fontColorAlt};
  text-align: left;
  width: 650px;
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
  ${(props) =>
    props.alt &&
    css`
      font-size: 14px;
      font-weight: 400;
      margin-top: 10px;
      width: 300px;
    `}
  @media only screen and (max-width: 1124px) {
    width: 600px;
  }
  @media only screen and (max-width: 1070px) {
    text-align: center;
  }
  @media only screen and (max-width: 622px) {
    width: auto;
    padding: 0px 10px 0px 10px;
  }
`;

const BtnRow = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 1070px) {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

const A = styled.a`
  margin-top: 45px;
`;

const Button = styled.button`
  font-family: "Lexend", sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  border-radius: 5px;
  border: none;
  width: 160px;
  height: 40px;
  background-color: #c44536;
  transition: 0.4s;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

