import React from "react";
import styled from "styled-components";

const ForwardIcon = (props) => {
  return (
    <Div>
      <svg
        width="17"
        height="auto"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Path
          d="M10.2764 1L23.5 14.9219L10.2764 28.8438L7 25.3944L16.9473 14.9219L7 4.44936L10.2764 1Z"
          fill="#646775"
        />
      </svg>
    </Div>
  );
};

export default ForwardIcon;

const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2px;
`;

const Path = styled.path`
  fill: ${(props) => props.theme.fontColorAlt};
`;
