import React from "react";
import styled  from "styled-components";

// Socials
import Discord from "../../assets/Socials/Discord";
import Telegram from "../../assets/Socials/Telegram";
import Twitter from "../../assets/Socials/Twitter";
import Github from "../../assets/Socials/Github";
import Medium from "../../assets/Socials/Medium";
import Youtube from "../../assets/Socials/Youtube";

function Footer() {
  return (
    <Section>
      <SocialRow>
        <A href="https://t.me/saffronfinance" target="_blank">
          <Telegram width={"35px"} mr={"20px"} />
        </A>
        <A href="https://discord.com/invite/pDXpXKY" target="_blank">
          <Discord width={"35px"} mr={"20px"} />
        </A>
        <A href="https://twitter.com/saffronfinance_" target="_blank">
          <Twitter width={"35px"} mr={"20px"} />
        </A>
        <A href="https://github.com/saffron-finance/saffron" target="_blank">
          <Github width={"35px"} mr={"20px"} />
        </A>
        <A href="https://medium.com/saffron-finance" target="_blank">
          <Medium width={"35px"} mr={"20px"} />
        </A>
        <A href="https://www.youtube.com/channel/UCk_ZDXcc9Z56p9HWp7tFArA" target="_blank">
          <Youtube width={"35px"} />
        </A>
      </SocialRow>
      <Copyright>
        Copyright © 2025 Saffron Finance.
      </Copyright>
    </Section>
  );
}

export default Footer;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  margin-top: 100px;
`;

const SocialRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 25px;
`;

const A = styled.a`
  text-decoration: none;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    opacity: 0.5;
  }
`;

const Copyright = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.fontColorAlt};
  opacity: 0.5;
  font-weight: 400;
  padding-bottom: 15px;
  @media screen and (max-width: 392px) {
    font-size: 12px;
  }
  @media screen and (max-width: 327px) {
    font-size: 10px;
  }
`;
