import React from "react";
import styled from "styled-components";

const YoutubeIcon = (props) => {
  return (
    <Div>
      <svg
        width={props.width}
        height="auto"
        viewBox="0 0 31 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Path
          d="M27.8914 9.30405C27.7443 8.75819 27.4567 8.26039 27.0574 7.86018C26.6581 7.45997 26.161 7.17133 25.6155 7.02296C23.5927 6.46755 15.5004 6.45851 15.5004 6.45851C15.5004 6.45851 7.40943 6.44946 5.38538 6.98034C4.8402 7.13553 4.34407 7.42826 3.94461 7.83043C3.54516 8.2326 3.25579 8.7307 3.1043 9.27692C2.57084 11.2997 2.56568 15.495 2.56568 15.495C2.56568 15.495 2.56051 19.711 3.09009 21.7131C3.38718 22.82 4.25905 23.6945 5.3673 23.9929C7.41072 24.5483 15.4811 24.5573 15.4811 24.5573C15.4811 24.5573 23.5733 24.5664 25.5961 24.0368C26.1418 23.8887 26.6394 23.6007 27.0397 23.2013C27.44 22.8019 27.7291 22.305 27.8785 21.7596C28.4132 19.7381 28.4171 15.5441 28.4171 15.5441C28.4171 15.5441 28.4429 11.3268 27.8914 9.30405V9.30405ZM12.9119 19.3816L12.9184 11.6316L19.6441 15.5131L12.9119 19.3816V19.3816Z"
          fill="white"
        />
      </svg>
    </Div>
  );
};

export default YoutubeIcon;

const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Path = styled.path`
  fill: ${(props) => props.theme.fontColorAlt};
`;
