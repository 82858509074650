import React from "react";
import styled from "styled-components";

const GovernanceForumIcon = (props) => {
  return (
    <Div mr={props.mr}>
      <svg
        width={props.width}
        height="auto"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Path
          d="M16.25 17.5H20.625C21.6588 17.5 22.5 16.6588 22.5 15.625V4.375C22.5 3.34125 21.6588 2.5 20.625 2.5H4.375C3.34125 2.5 2.5 3.34125 2.5 4.375V22.5L9.16625 17.5H16.25ZM5 17.375L5.1925 17.355L5 17.5V17.375Z"
          fill="#646775"
        />
        <Path
          d="M25.625 10H25V17.5013C25 18.8763 23.8838 19.9925 22.5125 20H10V20.625C10 21.6588 10.8412 22.5 11.875 22.5H20.8337L27.5 27.5V11.875C27.5 10.8412 26.6588 10 25.625 10Z"
          fill="#646775"
        />
      </svg>
    </Div>
  );
};

export default GovernanceForumIcon;

const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${(props) => props.mr || "0"};
`;

const Path = styled.path`
  fill: ${(props) => props.theme.fontColorAlt};
`;
