import React, { useState } from "react";
import styled from "styled-components";

// Icons
import AcademyIcon from "../../assets/Socials/Academy";
import BackIcon from "../../assets/Socials/Back";
import DocumentationIcon from "../../assets/Socials/Documentation";
import GovernanceForumIcon from "../../assets/Socials/GovernanceForum";
import PodcastIcon from "../../assets/Socials/Podcast";
import ForwardIcon from "../../assets/Socials/Forward";
import SaffronAppIcon from "../../assets/Socials/SaffronAppIcon";
import VoteIcon from "../../assets/Socials/VoteIcon";

// Socials
import Discord from "../../assets/Socials/Discord";
import Github from "../../assets/Socials/Github";
import Medium from "../../assets/Socials/Medium";
import Telegram from "../../assets/Socials/Telegram";
import Twitter from "../../assets/Socials/Twitter";
import Youtube from "../../assets/Socials/Youtube";

function KebabMenu({ isKebabOpen }) {
  const [activeMenu, setActiveMenu] = useState("main"); // "main", "socials", or "governance"

  const menuItems = [
    { text: "Launch App", icon: <SaffronAppIcon width="20px" />, link: "https://app.saffron.finance/" },
    { text: "Saffron Academy", icon: <AcademyIcon width="20px" />, link: "https://academy.saffron.finance/" },
    { text: "Documentation", icon: <DocumentationIcon width="20px" />, link: "https://docs.saffron.finance/" },
    { text: "Saffron Podcast", icon: <PodcastIcon width="20px" />, link: "https://academy.saffron.finance/podcast" },
  ];

  const governanceItems = [
    { text: "Governance Forum", icon: <GovernanceForumIcon width="20px" />, link: "https://gov.saffron.finance/" },
    { text: "Vote", icon: <VoteIcon width="20px" />, link: "https://vote.saffron.finance/#/" },
  ];

  const socialItems = [
    { text: "Telegram", icon: <Telegram width="20px" />, link: "https://t.me/saffronfinance" },
    { text: "Discord", icon: <Discord width="20px" />, link: "https://discord.com/invite/pDXpXKY" },
    { text: "Twitter", icon: <Twitter width="20px" />, link: "https://twitter.com/saffronfinance_" },
    { text: "Github", icon: <Github width="20px" />, link: "https://github.com/saffron-finance/saffron" },
    { text: "Medium", icon: <Medium width="20px" />, link: "https://medium.com/saffron-finance" },
    { text: "Youtube", icon: <Youtube width="20px" />, link: "https://www.youtube.com/channel/UCk_ZDXcc9Z56p9HWp7tFArA" },
  ];

  return (
    <Kebab>
      <KebabBody isKebabOpen={isKebabOpen}>
        {activeMenu === "main" && (
          <>
            {menuItems.map(({ text, icon, link }) => (
              <StyledItem key={text} href={link} target="_blank">
                <KebabText>{text}</KebabText>
                {icon}
              </StyledItem>
            ))}
            <StyledItem onClick={() => setActiveMenu("socials")}>
              <KebabText>Socials</KebabText>
              <ForwardIcon width="20px" />
            </StyledItem>
            <StyledItem onClick={() => setActiveMenu("governance")}>
              <KebabText>Governance</KebabText>
              <ForwardIcon width="20px" />
            </StyledItem>
          </>
        )}

        {activeMenu === "socials" && (
          <>
            <BackButton onClick={() => setActiveMenu("main")} />
            {socialItems.map(({ text, icon, link }) => (
              <StyledItem key={text} href={link} target="_blank">
                <KebabText>{text}</KebabText>
                {icon}
              </StyledItem>
            ))}
          </>
        )}

        {activeMenu === "governance" && (
          <>
            <BackButton onClick={() => setActiveMenu("main")} />
            {governanceItems.map(({ text, icon, link }) => (
              <StyledItem key={text} href={link} target="_blank">
                <KebabText>{text}</KebabText>
                {icon}
              </StyledItem>
            ))}
          </>
        )}
      </KebabBody>
    </Kebab>
  );
}

export default KebabMenu;

// Styles
const Kebab = styled.div`
  z-index: 100;
`;

const KebabBody = styled.div`
  position: absolute;
  margin-top: 25px;
  margin-left: 60px;
  width: 220px;
  border-radius: 10px;
  background: ${(props) => props.theme.cardColor};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  visibility: ${({ isKebabOpen }) => (isKebabOpen ? "visible" : "hidden")};

  @media only screen and (max-width: 446px) {
    margin-left: -105px;
  }
`;

const StyledItem = styled.a`
  cursor: pointer;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  margin: 5px 0;
  border-radius: 10px;
  &:hover {
    filter: ${(props) => props.theme.filterColor};
  }
`;

const KebabText = styled.div`
  padding-left: 10px;
  font-size: 15px;
  font-weight: 400;
  color: ${(props) => props.theme.fontColorAlt};
`;

const BackButton = ({ onClick }) => (
  <StyledItem as="div" onClick={onClick}>
    <BackIcon width="30px" />
  </StyledItem>
);
