import React from "react";
import styled from "styled-components";

const AcademyIcon = (props) => {
  return (
    <Div mr={props.mr}>
      <svg
        width={props.width}
        height="auto"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Path
          d="M2.5 8.75V10L16.25 15L27.5 10V8.75L13.75 5L2.5 8.75Z"
          fill="#646775"
        />
        <Path
          d="M5 13.75V19.0838C5 21.11 10.0012 23.95 16.25 23.7512C21.25 23.5937 24.4825 21.2863 25 19.4175C25.03 19.3063 25.0463 19.195 25.0463 19.0825V13.75L16.25 17.5L10 15.4163V19.4325L8.75 18.9775V15L5 13.75Z"
          fill="#646775"
        />
      </svg>
    </Div>
  );
};

export default AcademyIcon;

const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${(props) => props.mr || "0"};
`;

const Path = styled.path`
  fill: ${(props) => props.theme.fontColorAlt};
`;
