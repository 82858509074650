import React from "react";
import styled, { css } from "styled-components";

// Icons
import ArrowIcon from "../../assets/AcademyAssets/Arrow";

function FeatureCards() {
  // const [sfiapr, setSFIAPR] = useState();
  // const [sfitvl, setSFITVL] = useState();
  // const [sfiEthAprUni, setSFIETHAPRUNI] = useState();
  // const [sfiEthTvlUni, setSFIETHTVLUNI] = useState();
  // const [sfiEthAprSushi, setSFIETHAPRSUSHI] = useState();
  // const [sfiEthTvlSushi, setSFIETHTVLSUSHI] = useState();
  // const [prtEth, setprtEth] = useState();
  // const [prtEthTvl, setprtEthTvl] = useState();

  // const getData = () => {
  //   Axios.get("https://mainnet-api.saffron.finance/v2/pools").then(
  //     (response) => {
  //       setSFITVL(response.data.stakingPools[0].tvlUsd);
  //       setSFIAPR(parseInt(response.data.stakingPools[0].apr));
  //       setSFIETHTVLUNI(response.data.stakingPools[1].tvlUsd);
  //       setSFIETHAPRUNI(parseInt(response.data.stakingPools[1].apr));
  //       setSFIETHTVLSUSHI(response.data.stakingPools[2].tvlUsd);
  //       setSFIETHAPRSUSHI(parseInt(response.data.stakingPools[2].apr));
  //       setprtEthTvl(response.data.stakingPools[5].tvlUsd);
  //       setprtEth(parseInt(response.data.stakingPools[5].apr));
  //     }
  //   );
  // };

  // useEffect(() => {
  //   getData();
  // }, []);

  return (
    <Header id="Acad">
      <Container>
        <PreTitle>Academy</PreTitle>
        <StyledText>Become an Expert with Saffron.</StyledText>
        <Subtext>
          Dive into Saffron Finance through our Essentials, DeFi Basics,
          Newsletters, and Podcasts all in one place on{" "}
          <Span>Saffron Academy.</Span>
        </Subtext>
        <Row>
          <A href="https://academy.saffron.finance/" target="_blank">
            <Card>
              <InnerCardContainer>
                <StyledText cardtext>Home</StyledText>
              </InnerCardContainer>
              <ArrowIcon />
            </Card>
          </A>
          <A href="https://academy.saffron.finance/explore" target="_blank">
            <Card green>
              <InnerCardContainer>
                <StyledText cardtext>Explore</StyledText>
              </InnerCardContainer>
              <ArrowIcon />
            </Card>
          </A>
        </Row>
        <Row>
          <A href="https://medium.com/saffron-finance" target="_blank">
            <Card orange>
              <InnerCardContainer>
                <StyledText cardtext>Weekly Newsletters</StyledText>
              </InnerCardContainer>
              <ArrowIcon />
            </Card>
          </A>
          <A href="https://academy.saffron.finance/#podcast-page-section" target="_blank">
            <Card blue>
              <InnerCardContainer>
                <StyledText cardtext>Podcasts</StyledText>
              </InnerCardContainer>
              <ArrowIcon />
            </Card>
          </A>
        </Row>
      </Container>
    </Header>
  );
}

export default FeatureCards;

const Header = styled.div`
  margin: 0 auto;
  width: 100%;
  margin-top: 150px;
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
  background-color: ${(props) => props.theme.body};
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media only screen and (max-width: 489px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const PreTitle = styled.span`
  color: ${(props) => props.theme.fontColorAlt};
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  margin-bottom: 10px;
`;

const StyledText = styled.h2`
  margin: 0;
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 500;
  color: ${(props) => props.theme.fontColor};
  ${(props) =>
    props.cardtext &&
    css`
      margin-bottom: 0px;
      font-size: 25px;
      font-weight: 400;
      color: #ffffff;
    `}
`;

const Subtext = styled.p`
  font-size: 18px;
  font-weight: 300;
  width: 750px;
  color: ${(props) => props.theme.fontColorAlt};
  margin: 0;
  @media only screen and (max-width: 1070px) {
    padding-bottom: 55px;
    width: 600px;
  }
  @media only screen and (max-width: 606px) {
    width: auto;
    padding: 0px 15px 25px 15px;
  }
`;

const Span = styled.span`
  color: #c44536;
  font-weight: 400;
`;

const Row = styled.div`
  margin-top: 45px;
  max-width: 1000px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 1070px) {
    flex-direction: column;
    justify-content: center;
    margin-top: 0;
    max-width: 480px;
    width: 100%;
  }
`;

const A = styled.a`
  width: 100%;
`;

const Card = styled.div`
  cursor: pointer;
  display: flex;
  flex-diretion: row;
  justify-content: space-between;
  align-items: center;
  max-width: 480px;
  width: 100%;
  height: 100px;
  background-color: #a81ce9;
  border-radius: 10px;
  transition: 0.2s;
  &:hover {
    opacity: 0.7;
  }
  @media only screen and (max-width: 1070px) {
    margin-top: 25px;
  }
  ${(props) =>
    props.green &&
    css`
      background-color: #14800a;
    `}
  ${(props) =>
    props.orange &&
    css`
      background-color: #ca4f12;
    `}
      ${(props) =>
    props.blue &&
    css`
      background-color: #006bd6;
    `}
`;

const InnerCardContainer = styled.div`
  padding-left: 20px;
`;
