import styled from "styled-components";

import HeaderImage from "../../assets/sfiheadertest.svg";

function Landing(props) {
  return (
    <Header>
      <Container>
        <Row>
          {/* <Notification>
            <NotificationText bold>Introducing Saffron on KCC</NotificationText>
            <NotificationText>Read the announcement</NotificationText>
          </Notification> */}
          <StyledText>
            Risk Adjustment for <br /> Decentralized Finance
          </StyledText>
          <SubText>
            Saffron is a peer to peer risk adjustment protocol. Users customize
            their risk and return profiles by selecting their own degree of
            exposure to underlying platforms.
          </SubText>
          <A href="https://app.saffron.finance/" target="_blank" rel="noreferrer">
          <Button>Start Earning</Button>
          </A>
        </Row>
        <HeaderDiv>
          <Img src={HeaderImage}/>
        </HeaderDiv>
      </Container>
    </Header>
  );
}

export default Landing;

const Header = styled.div`
  margin: 0 auto;
  max-width: 1300px;
  width: 100%;
  padding-top: 60px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 1020px) {
    flex-direction: column-reverse;
    justify-content: center;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1306px) {
    padding-left: 15px;
  }
  @media only screen and (max-width: 1020px) {
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-left: 0px;
  }
`;

const HeaderDiv = styled.div`
@media only screen and (max-width: 1306px) {
  padding-right: 15px;
}
@media only screen and (max-width: 1158px) {
  padding-right: 20px;
}
@media only screen and (max-width: 1112px) {
  padding-right: 25px;
}
@media only screen and (max-width: 1020px) {
  margin-bottom: 55px;
}
@media only screen and (max-width: 573px) {
  position: relative !important;
  overflow: hidden;
}
`;

const Img = styled.img`
height: 310px;
width: auto;
@media only screen and (max-width: 1158px) {
  height: 280px;
}
@media only screen and (max-width: 1112px) {
  height: 250px;
}
@media only screen and (max-width: 1020px) {
  height: 310px;
  transform: skewY(5deg);
}
@media only screen and (max-width: 573px) {
  overflow: hidden;
}
`

const StyledText = styled.h1`
  color: ${(props) => props.theme.fontColor};
  font-weight: 500;
  font-size: 48px;
  margin: 0;
  @media only screen and (max-width: 597px) {
    font-size: 42px;
   }
   @media only screen and (max-width: 497px) {
    font-size: 38px;
   }
   @media only screen and (max-width: 433px) {
    font-size: 32px;
   }
`;

const SubText = styled.div`
  color: ${(props) => props.theme.fontColorAlt};
  font-size: 16px;
  font-weight: 300;
  margin-top: 15px;
  display: flex;
  line-height: 25px;
  width: 560px;
  align-items: center;
  @media only screen and (max-width: 597px) {
    font-size: 16px;
    width: auto;
    text-align: center;
    padding-right: 10px;
    padding-left: 10px;
   }
`;

const Button = styled.button`
  font-family: "Lexend", sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  border-radius: 5px;
  border: none;
  width: 160px;
  height: 40px;
  background-color: #c44536;
  transition: 0.4s;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const A = styled.a`
width: 160px;
margin-top: 45px;
`;

// const Notification = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: flex-start;
//   background-color: ${(props) => props.theme.announcementBg};
//   border-radius: 5px;
//   height: 30px;
//   width: 430px;
//   margin-bottom: 25px;
// `

// const NotificationText = styled.span`
//   color: ${(props) => props.theme.announcementFont};
//   font-size: 13px;
//   font-weight: 300;
//   ${(props) => props.bold && css`
//     font-weight: 500;
//     font-size: 14px;
//     margin-left: 10px;
//     margin-right: 7px;
//   `}
// `